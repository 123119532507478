import React from 'react';
import SiteContentContext from './SiteContentContext';
import { SiteContentHtmlParser } from './SiteContentHtmlParser';
import { useQuery } from 'react-apollo';
import siteContentQuery from './siteContentQuery.gql';
import { convertPropsToObject } from '../ContentRender/ContentRender';
import { theme } from '../Theming/Theming';

export const convertPropsToSiteContentObject = item => {
  const props = item.properties.reduce((merged, current) => {
    const propValue = current.value?.value
      ? current.value.value
      : current.value;
    merged[current.name] =
      current.type === 'html' ? (
        <SiteContentHtmlParser content={propValue} />
      ) : current.type === 'dropdown' && current.name.search('show') !== -1 ? (
        propValue === 'yes' ? (
          true
        ) : (
          false
        )
      ) : (
        propValue
      );
    return merged;
  }, {});
  return props;
};

const SiteContent = ({ children }) => {
  const result = useQuery(siteContentQuery, {
    variables: { id: theme.config.settings.siteContentId }
  });

  const startPage = result?.data?.startPage;
  const items = startPage?.data?.items;

  const siteContentObject =
    items && items.filter(item => item.type === 'site-content-general')[0];

  //OLD VERSION OF SITECONTENT
  const otherObjects =
    items &&
    items
      .filter(item => item.type === 'site-content')
      .reduce((a, v) => {
        const props = convertPropsToObject(v);
        return {
          ...a,
          [v.properties[0].value.value]: {
            html: <SiteContentHtmlParser content={props.text.value} />,
            text: props.text.value,
            show: props.show.value === 'yes' ? true : false,
            image: props.image.value,
            link: props.link.value,
            option: props.option.value
          }
        };
      }, {});

  const props =
    siteContentObject && convertPropsToSiteContentObject(siteContentObject);

  return (
    <SiteContentContext.Provider value={{ ...props, ...otherObjects }}>
      {children}
    </SiteContentContext.Provider>
  );
};

export default SiteContent;
