import bodyFontReg from './Swis721_BT.woff2';
import bodyFontBold from './Swis721_BT_Bold.woff2';
import bodyFontBold2 from './Swis721_Cn_BT_Bold.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Swis721';
        src: url(${bodyFontReg}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Swis721';
        src: url(${bodyFontBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Swis721-cond';
        src: url(${bodyFontBold2}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
