import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { theme } from '../../Theming/Theming';

const useBuyButtons = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const buyButtons = theme.config.settings.buyButtons;

  if (
    buyButtons &&
    typeof buyButtons === 'object' &&
    typeof buyButtons[selectedChannel.name] !== 'undefined'
  ) {
    return buyButtons[selectedChannel.name];
  }

  return buyButtons;
};

export default useBuyButtons;
