export function getSelectedProductName(product, selected) {
  return selected ? selected.articleNumber : product.articleNumber;
}

export function getCombinedComment(comment1, comment2) {
  return comment1 + ' - ' + comment2;
}

function isPrint(name) {
  return name.search('print') !== -1;
}
function isCombined(name) {
  return name.search('-combined') !== -1;
}

const htmlDecode = text => {
  return text.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};

//TODO: REWRITE...
export const checkComments = items => {
  const printCommentName = 'print';
  const printCommentProductName = 'for';

  let commentProducts = [];

  //LOOP LIST AND SEND OUT ANY PRINT PRODUCT
  const itemsList = items.reduce((accumulator, item) => {
    if (isPrint(item.articleNumber)) {
      commentProducts.push(item);
      return accumulator;
    }

    //CHECK IF COMMENT IS ONE OF THE PRINT PRODUCT -COMMENT
    const comments = item.customerComments.filter(comment => {
      return isPrint(comment.name);
    });

    //START LOOKING
    if (comments && comments.length) {
      //MAIN PRODUCT HAS MULTIPLE COMMENT
      const multipleComments =
        comments[0].value.length &&
        comments.length > 1 &&
        comments[1].value.length
          ? true
          : false;

      //FETCH RELATED PRINT PRODUCTs
      const relatedPrint = items.filter(printItem => {
        const productName = getSelectedProductName(item.product, item.variant);
        if (
          isPrint(printItem.articleNumber) &&
          printItem.customerComments &&
          printItem.customerComments.length === 2
        ) {
          let hit = false;

          const commentName1 = printItem.customerComments[0].name;
          const commentValue1 = htmlDecode(printItem.customerComments[0].value);
          const commentName2 = printItem.customerComments[1].name;
          const commentValue2 = htmlDecode(printItem.customerComments[1].value);

          if (multipleComments) {
            if (
              isCombined(printItem.articleNumber) &&
              printItem.customerComments.length === 2 &&
              commentName1 === printCommentName &&
              commentValue1 ===
                getCombinedComment(comments[0].value, comments[1].value) &&
              commentName2 === printCommentProductName &&
              commentValue2 === productName
            )
              hit = true;
          } else {
            comments.forEach(comment => {
              if (
                commentName1 === printCommentName &&
                commentValue1 === comment.value &&
                commentName2 === printCommentProductName &&
                commentValue2 === productName
              )
                hit = true;
            });
          }
          return hit;
        }
        return false;
      });

      if (relatedPrint && relatedPrint.length)
        return [...accumulator, { ...item, relatedPrint: relatedPrint[0] }];
    }

    return [...accumulator, item];
  }, []);

  if (!itemsList || itemsList.length === 0) return commentProducts;
  return itemsList;
};
