import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import { Price } from '../Price';
import t from '@jetshop/intl';
import { useContext } from 'react';
import SiteContentContext from '../SiteContent/SiteContentContext';

const Wrapper = styled.div`
  &[data-list='true'] {
    height: 30px;
    ${theme.above.md} {
      height: 20px;
    }
    ${theme.above.xl} {
      height: 20px;
    }
  }
  &[data-list='false'] {
    width: 100%;
    display: flex;
  }
`;

const InnerWrapper = styled.div`
  background: ${theme.colors.primaryLight};
  height: 40px;
  &[data-list='true'] {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 1rem;
    justify-content: space-between;
    ${theme.above.md} {
      padding: 0 1.5rem;
    }
    ${theme.above.xl} {
      padding: 0 2rem;
    }
    .price {
      width: 65px;
      text-align: right;
    }
  }
  display: flex;
  align-items: center;
  &[data-list='false'] {
    padding: 0.9rem;
    .text {
      margin-right: 10px;
    }
    ${theme.below.md} {
      width: 100%;
    }
  }
`;

export const ProductDiscount = ({ product, list = false }) => {
  const { MemberDiscount: item } = useContext(SiteContentContext);

  if (!item || !item.show) return null;

  if (product.previousPrice.incVat > product.price.incVat) return null;

  const discount = parseFloat(item.html.props.content);
  const discountPrice = {
    exVat: product.price.exVat * (1 - discount),
    incVat: product.price.incVat * (1 - discount),
    vat: product.price.vat * (1 - discount)
  };

  return (
    <Wrapper data-list={list}>
      <InnerWrapper data-list={list} className="product-discount-inner-wrapper">
        <h6 className="text">
          <span>{t('Member price skiforeningen')}</span>
        </h6>
        <h5 className="price">
          <Price price={discountPrice} />
        </h5>
      </InnerWrapper>
    </Wrapper>
  );
};
