import React from 'react';
import parse from 'html-react-parser';
import { useContext } from 'react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

export const ExtraMenuLinks = ({ tag = 'li' }) => {
  const Element = tag;
  const { MenuLinks: item } = useContext(SiteContentContext);

  if (!item || !item.html) return null;

  let list = parse(item.html.props.content);

  if (!Array.isArray(list)) list = [list];

  return list.map((listItem, index) => {
    if (!listItem.props) return false;
    if (listItem) return <Element key={index + 20}>{listItem}</Element>;
    return null;
  });
};
