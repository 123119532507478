import CartProvider from '@jetshop/core/components/Query/CartProvider';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import React, { Fragment } from 'react';
import cartQuery from './queries/cartQuery.gql';
import CartContent from './CartContent';

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {result =>
      result.data && result.data.cart ? (
        <Fragment>
          {/* Flyout is used for desktop */}
          {/*<FlyoutTarget id="cart-flyout">
            {flyout => <CartFlyoutView modal={flyout} cart={cart} {...props} className="flyout" />}
           </FlyoutTarget>*/}

          {/* Drawer is used for mobile */}
          <DrawerTarget id="cart-drawer">
            {drawer => (
              <Drawer isOpen={drawer.isOpen} right size={420}>
                <CartContent
                  modal={drawer}
                  result={result}
                  {...props}
                  className="drawer"
                />
              </Drawer>
            )}
          </DrawerTarget>
        </Fragment>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
