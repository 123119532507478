import bodyFontReg from './AvenirLTStd-Book.woff';
import bodyFontBold from './AvenirBlack.woff';
import bodyFontMedium from './AvenirLTStd-Medium.woff';

import bodyFontReg2 from './AvenirLTStd-Book.woff2';
import bodyFontBold2 from './AvenirBlack.woff2';
import bodyFontMedium2 from './AvenirLTStd-Medium.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Avenir';
        src: url(${bodyFontReg}) format('woff'),
          url(${bodyFontReg2}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Avenir';
        src: url(${bodyFontBold}) format('woff'),
          url(${bodyFontBold2}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Avenir';
        src: url(${bodyFontMedium}) format('woff'),
          url(${bodyFontMedium2}) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
