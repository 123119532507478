import * as React from 'react';
import { injectFont } from './injectFont';
function handleTypekitFonts(id) {
  // Check for document first as this should not run on the server
  document &&
    import('store-css').then(module =>
      module.default.css(`https://use.typekit.net/${id}.css`, {
        crossOrigin: 'anonymous',
        storage: 'session'
      })
    );
}
export default class TypekitFont extends React.PureComponent {
  constructor() {
    super(...arguments);
    // Uses Google's CDN to request the desired font
    this.componentDidMount = () => {
      const { primaryFont = '', secondaryFonts = [], id } = this.props;
      injectFont(primaryFont, secondaryFonts);
      handleTypekitFonts(id);
    };
  }
  render() {
    return null;
  }
}
