import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import t from '@jetshop/intl';
import { Price } from '../Price';
import { theme } from '../Theming/Theming';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import Button from '../ui/Button';
import CartItem from './CartItem';
import CloseButton from '../ui/CloseButton';
import DrawerHeader from '../ui/DrawerHeader';
//import FreeShipping from './FreeShipping';
import ProductRow from '../ui/ProductRow/ProductRow';
//import useFreeShippingCheck from '@jetshop/core/hooks/useFreeShippingCheck';
import { checkComments } from '../ProductPage/PrintProducts/checkComments';
import {
  KlaviyoTrackAddToCartItems,
  klaviyoTrackStartedCheckout
} from '../Klaviyo';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const Wrapper = styled('div')`
  background: white;
  color: ${theme.colors.black};
  &.flyout {
    position: absolute;
    z-index: 999;
    background: white;
    right: 0;
    color: ${theme.colors.font};
    width: 420px;
    min-width: 19rem;
    max-width: 100%;
    border: 1px solid ${theme.colors.border};
  }
`;

const StyledCloseButton = styled(CloseButton)`
  padding: 10px;
  margin: -10px;
  position: absolute;
  right: 3px;
  top: 30px;
`;

const StyledDrawerHeader = styled(DrawerHeader)`
  background: ${theme.colors.white};
  margin: 0 1.5rem;
  padding: 0.5rem 0;
  align-items: flex-start;
  justify-content: flex-end;
  h2 {
    font-size: 1.4rem;
  }
`;
const Summary = styled('section')`
  background: #fff;
  padding: 1rem 1.5rem;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }

  .discounts {
    padding: 5px;
    background: ${theme.colors.greys[0]};
    margin-bottom: 0.5rem;
  }
`;
const CartItems = styled('section')`
  padding: 1rem 1.5rem;
  ${theme.above.md} {
    //max-height: 360px;
    //overflow: auto;
  }
`;

const TotalSummary = styled('div')`
  font-size: 1.25rem;
  color: ${theme.colors.font};
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const SummaryTitle = styled('h3')`
  text-transform: none;
  font-size: 1.2rem;
`;

const Flex = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const ExtraProductsWrapper = styled('div')`
  padding: 1rem 1.5rem;
`;

const Checkout = styled(Button)``;

const CartContent = ({ result, modal, ...rest }) => {
  const cart = result?.data?.cart;
  const checkoutUrl = result?.data?.cart?.externalCheckoutUrl;
  const discounts = result?.data?.cart?.aggregatedDiscounts ?? [];
  const track = useTracker();
  const { selectedChannel } = useContext(ChannelContext);
  const items = checkComments(cart.items);

  /*const total = cart.productTotal || { incVat: 0, exVat: 0, vat: 0 };
  const freeShipping = useFreeShippingCheck(total);*/

  if (items.length === 0) {
    return (
      <Wrapper {...rest}>
        <StyledDrawerHeader>
          <h2>{t('No items in cart.')}</h2>
          <StyledCloseButton onClick={modal.hideTarget} />
        </StyledDrawerHeader>
      </Wrapper>
    );
  }

  return (
    <Wrapper {...rest}>
      <KlaviyoTrackAddToCartItems
        cart={result.data.cart}
        selectedChannel={selectedChannel}
      />
      <StyledDrawerHeader>
        <h2>{t('Your Cart')}</h2>
        <StyledCloseButton onClick={modal.hideTarget} />
      </StyledDrawerHeader>
      <Summary>
        {discounts.length > 0 && (
          <div className="discounts">
            <h4>{t('Cart total')}</h4>
            <Flex>
              <label>{t('Order value')}</label>
              <Price price={cart.productPreviousTotal} />
            </Flex>
            <h4>{t('Discounts')}</h4>
            {discounts.map(discount => {
              return (
                <Flex key={discount.name}>
                  <label>{discount.name}</label>
                  <Price price={discount.value} style={{ display: 'inline' }} />
                </Flex>
              );
            })}
          </div>
        )}
        <TotalSummary>
          <SummaryTitle>{t('Total')}</SummaryTitle>
          <Price price={cart.productTotal} />
        </TotalSummary>
        {checkoutUrl && (
          <Checkout buy>
            <a
              href={checkoutUrl}
              onClick={event => {
                track(trackCartCheckoutEvent({ cart: cart }));
                klaviyoTrackStartedCheckout(result.data.cart, selectedChannel);
              }}
            >
              {t('Check out')}
            </a>
          </Checkout>
        )}
      </Summary>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} close={modal.hideTarget} />
        ))}
      </CartItems>
      <ExtraProductsWrapper>
        <ProductRow
          id={1496}
          slider={true}
          orderBy={'BESTSELLER'}
          title={'Recommended products'}
          quickBuy={false}
          sliderSettings={{
            dots: false,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2
          }}
        />
      </ExtraProductsWrapper>
    </Wrapper>
  );
};

export default CartContent;
