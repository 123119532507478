import bodyFontReg from './tipo-pepep.woff2';
import bodyFontBold from './tipo-pepep-bold.woff2';
import bodyFont2 from './robot-radical-italic.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'tipo-pepep';
        src: url(${bodyFontReg}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'tipo-pepep';
        src: url(${bodyFontBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'robot-radical-italic';
        src: url(${bodyFont2}) format('woff2');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
      }
    }
  `;
}
