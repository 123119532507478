import { createBreakPoints } from './helpers';
import { shopTheme } from './switch';

const labels = ['xs', 'sm', 'md', 'lg', 'xl'];
const breakpoints = ['0px', '20rem', '40rem', '64rem', '80rem', '100rem'];

export const mediaQueries = createBreakPoints(labels, breakpoints);
const cssMediaQueries = createBreakPoints(labels, breakpoints, true);

const fontSizes = [
  '12px',
  '14px',
  '16px',
  '20px',
  '24px',
  '32px',
  '48px',
  '64px',
  '72px'
];
const space = [
  '0px',
  '8px',
  '16px',
  '24px',
  '32px',
  '40px',
  '48px',
  '56px',
  '64px'
];
const fontWeights = {
  light: 300,
  regular: 400,
  semibold: 700
};

export const fonts = {
  primary: 'Source Sans Pro'
};

const customTheme = shopTheme();

export const theme = {
  ...cssMediaQueries,
  fontSizes,
  space,
  fontWeights,
  fonts,
  ...customTheme
};
