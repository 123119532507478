import { useEffect } from 'react';
import { useContext } from 'react';
import SiteContentContext from '../SiteContent/SiteContentContext';

const NewsletterPopup = () => {
  const client = typeof document !== 'undefined';
  const { NewsletterPopup: item } = useContext(SiteContentContext);

  useEffect(() => {
    if (client && item && item.show && item.html?.props?.content) {
      (function() {
        var s = document.createElement('script');
        s.id = 'mnm-widget';
        s.type = 'text/javascript';
        s.async = !0;
        s.setAttribute(
          'data-params',
          '{"config":"' + item.html.props.content + '.json"}'
        );
        s.src = 'https://d8g8olsgmw166.cloudfront.net/widget.v4.min.gz.js';
        document.getElementsByTagName('head')[0].appendChild(s);
      })();
    }
  }, [client, item]);

  return null;
};

export default NewsletterPopup;
