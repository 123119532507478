import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';
import ProductLink from '@jetshop/ui/ProductLink';
import Badges from '@jetshop/ui/ProductList/Badges';
import React, { useEffect, useState } from 'react';
import { styled } from 'linaria/react';
import { GridPrice } from '../Price';
import t from '@jetshop/intl';
import { ColorCircles } from './ColorCircles/ColorCircles';
import { Favourite } from '../ProductList/Favourite';
import { theme } from '../Theming/Theming';
import { ProductDiscount } from './ProductDiscount';
import StockStatusIndicator from '../ProductPage/StockStatus/StockStatusIndicator';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import { PercentPrice } from '../ProductPage/Price/PercentPrice';

export const ProductCardInner = styled('div')`
  padding: ${theme.productGrid.product.padding[0]};
  background-color: ${theme.productGrid.product.background};
  border: ${theme.productGrid.product.border};
  position: relative;
  line-height: 1;
  min-width: 0;
  ${theme.above.md} {
    padding: ${theme.productGrid.product.padding[1]};
  }
  ${theme.above.xl} {
    padding: ${theme.productGrid.product.padding[2]};
  }
  > a {
    position: relative;
  }
`;

export const Details = styled('div')`
  margin: 1rem 0 0.5rem;
  position: relative;
  ${
    theme.productGrid.product.centerAlign
      ? `text-align: center;`
      : `text-align:left;`
  }
  .variant-text {
    display: block;
    margin-top: 1rem;
    text-transform: uppercase;
    .variant-text-value {
      > span {
        margin-left: 3px;
        &:not(:last-child){
          &:after{
            content "&";
            margin:  0 3px;
          }
        }
      }
    }
  }
`;

const ImageWrapper = styled.div``;

export const DetailsInner = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  flex-grow: 1;
  align-self: stretch;
  align-items: flex-end;
  > * {
    flex-grow: 0;
    width: 100%;
  }
`;

export const BadgeWrapper = styled('div')`
  color: white;
  text-align: center;
  font-size: 0.8rem;
  img {
    width: auto;
    height: auto;
    max-width: 70px;
    ${theme.below.md} {
      max-width: 50px;
    }
  }
`;

export const Name = styled(theme.productGrid.product.name.tag)`
  margin: 0 0 0.5rem 0 !important;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1;
  padding: 0;
  font-family: ${theme.productGrid.product.name.fontFamily} !important;
  font-weight: ${theme.productGrid.product.name.fontWeight} !important;
  height: 46px;
  overflow: hidden;
  ${theme.below.lg} {
    height: 44px;
    overflow: hidden;
  }
  ${theme.below.sm} {
    height: 64px;
  }
`;
export const SubName = styled('p')`
  margin: 0 0 0 0;
  height: 27px;
  align-self: stretch;
  flex-grow: 1;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${theme.below.md} {
    font-size: 0.9rem;
  }
`;
export const ListPrice = styled(GridPrice)`
  margin-top: 0.5rem;
  padding-right: 2rem;
  ${theme.productGrid.product.centerAlign
    ? `padding: 0 2rem;`
    : `padding-right: 2rem;`}
  [data-flight-price] {
    ${theme.productGrid.product.centerAlign
      ? `justify-content: center;`
      : `justify-content: flex-start;`}
  }
`;

const StockStatusWrapper = styled('div')`
  margin-bottom: 5px;
  > p {
    display: block !important;
    font-size: 0.8rem;
  }
`;

const ProductCardComponent = ({
  product,
  imageAspect,
  imageSizes,
  children,
  showFavorite,
  loadImageEagerly,
  showVariants,
  ...linkProps
}) => {
  //HAS IMAGES
  const hasImages = product.images && product.images.length > 0;

  const firstImage = hasImages ? product.images[0] : false;
  const secondImage =
    hasImages && product.images.length > 1
      ? product.images[1]
      : product.images[0];

  const [image, setImage] = useState(firstImage);

  const [imageIndex, setImageIndex] = useState(1);

  useEffect(() => {
    if (secondImage && imageIndex === 2 && image !== secondImage)
      setImage(secondImage);
    else if (firstImage && imageIndex === 1 && image !== firstImage) {
      setImage(firstImage);
    }
  }, [imageIndex, image, firstImage, secondImage]);

  let badges = [...product.badges];

  product.isPackage &&
    badges.push({
      location: 'TOP_RIGHT',
      name: 'Package',
      text: t('Package'),
      style: 'package-badge'
    });

  const stockStatus = useStockStatus(product);
  return (
    <>
      <ProductCardInner className="product-card-inner">
        <ProductLink product={product} {...linkProps}>
          <ImageWrapper
            className="image-wrapper"
            onMouseEnter={() => setImageIndex(2)}
            onMouseLeave={() => setImageIndex(1)}
          >
            {hasImages ? (
              <Image
                sizes={imageSizes}
                aspect={imageAspect}
                alt={image.alt}
                src={image.url}
                modifiedDate={image.modifiedDate}
                critical={loadImageEagerly}
              >
                <BadgeWrapper>
                  <Badges badges={product.badges} />
                </BadgeWrapper>
              </Image>
            ) : (
              <Image src={transparentDataImg} />
            )}
            <PercentPrice
              className="badge"
              price={product.price}
              previousPrice={product.previousPrice}
            />
          </ImageWrapper>
        </ProductLink>
        <ColorCircles product={product} />
        <Details className="product-card-detail">
          {showFavorite && (
            <Favourite
              product={product}
              style={{
                position: 'absolute'
              }}
            />
          )}
          <DetailsInner>
            <header>
              <ProductLink product={product}>
                <Name>{product.name}</Name>
              </ProductLink>
              <SubName className="sub-name">{product.subName}</SubName>
            </header>
            <StockStatusWrapper>
              <StockStatusIndicator
                status={stockStatus.status}
                text={stockStatus.text}
              />
            </StockStatusWrapper>
            <ListPrice {...product} />
            {product.isPackage && (
              <span className="package-price-label">{t('Package Price')}</span>
            )}
          </DetailsInner>
        </Details>
        {children}
        {theme.productGrid.product.discount && (
          <ProductDiscount product={product} list={true} />
        )}
      </ProductCardInner>
    </>
  );
};

export function ProductCard({
  product,
  className,
  imageAspect = '1:1',
  imageSizes = [1 / 2, 1 / 2, 1 / 3, 1 / 4],
  forwardRef,
  as = 'div',
  children,
  showFavorite = true,
  showVariants = true,
  loadImageEagerly,
  ...linkProps
}) {
  const Tag = as;

  /*
  useEffect(() => {
    if (inView && !fetchVariants && checkForColor(product))
      setFetchVariants(true);
  }, [inView, fetchVariants, setFetchVariants, product]);
  */

  //PROPS TO PASS TO PRODUCTCARD COMP
  const props = {
    imageAspect,
    imageSizes,
    children,
    showFavorite,
    loadImageEagerly,
    showVariants,
    ...linkProps
  };

  return (
    <Tag
      className={`product-card ${className}`}
      data-testid="product"
      data-product-card
      ref={forwardRef}
    >
      <ProductCardComponent product={product} {...props} />
    </Tag>
  );
}
