import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/Image';
import MaxWidth, { MaxWidthWrapper, Inner } from '../MaxWidth';
import { theme } from '../../Theming/Theming';
import { getFontSize } from '../../Theming/helpers';
import { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';

const NewsletterBoxWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  ${MaxWidthWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }
  .image-wrapper {
    width: 100%;
    [data-flight-image-container] {
      ${theme.below.lg} {
        padding-bottom: 50% !important;
      }
      ${theme.below.md} {
        padding-bottom: 80% !important;
      }
      ${theme.below.sm} {
        padding-bottom: 110% !important;
      }
    }
  }
  .text-wrapper {
    background: ${theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem ${theme.general.pagePadding.sm};
    ${theme.above.sm} {
      padding: 4rem ${theme.general.pagePadding.md};
    }
    display: flex;
    flex-direction: column;
    h2,
    h3,
    h4,
    h5,
    p,
    label {
      color: ${theme.colors.font};
    }
    p {
      margin: 1em 0;
    }
    > * {
      width: 100%;
      max-width: 700px;
    }
    >form{
      .submit-area{
        display: flex;
        border: 2px solid #222;
        margin-top: 1rem;
        input[type="submit"] {
          width: 30%;
          height: 46px;
          line-height: 46px;
          cursor: pointer;
          background: ${theme.button.background};
          border: none;
          color: ${theme.button.color};
          ${getFontSize(theme.button.fontSize)};
          font-family: ${theme.button.fontFamily} !important;
          transition: all 0.1s ease-in;
          background: #FFF;
          color: #222;
          text-transform: uppercase;
          opacity: 1 !important;
          border-left: 2px solid #222;
          :hover {
          }
        }
        input[type="email"] {
          border: none; //1px solid ${theme.colors.buy};
          height: 46px;
          line-height: 46px;
          width: 70%;
          padding-left: 20px;
          background: #fff;
          color: ${theme.colors.font};
          ::placeholder {
            color: ${theme.colors.font};
          }
          &:focus {
            ::placeholder {
            }
          }
        }
      }
    }
    .newsletter-wrapper {
      border: 2px solid ${theme.colors.font};
      max-width: none;
      > p {
        text-align: center;
      }
      input {
      }
      button {
        background: ${theme.colors.white};
        color: ${theme.colors.font};
        text-transform: uppercase;
        opacity: 1 !important;
        border-left: 2px solid ${theme.colors.font};
      }
    }
  }
  //TRANSPARENT TYPE
  &.transparent {
    ${MaxWidthWrapper} {
      width: 100%;
    }
    .text-wrapper {
      background: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      h2,
      h3,
      h4,
      h5,
      p,
      label {
        color: ${theme.colors.white};
      }
      .newsletter-wrapper {
        border: 2px solid ${theme.colors.white};
        input {
          background: transparent;
          color: ${theme.colors.white};
          ::placeholder {
            color: ${theme.colors.white};
          }
        }
        button {
          background: transparent;
          color: ${theme.colors.white};
          text-transform: uppercase;
          opacity: 1 !important;
          border: none;
          border-left: 2px solid ${theme.colors.white};
        }
      }
    }
  }
`;

const NewsletterBox = () => {
  const { Newsletter: item } = useContext(SiteContentContext);
  if (!item || !item.show) return null;
  return (
    <NewsletterBoxWrapper
      className={item.option === 'option2' ? 'transparent' : 'standard'}
    >
      {item.image && (
        <div className="image-wrapper">
          <Image
            aspect={'3:1'}
            sizes={[1, 1, 1 / 2, 1 / 2]}
            src={item.image}
            alt={item.image}
            crop={true}
            cover
          />
        </div>
      )}
      <MaxWidth>
        <Inner>
          <div className="text-wrapper">{item.html}</div>
        </Inner>
      </MaxWidth>
    </NewsletterBoxWrapper>
  );
};

export default NewsletterBox;
