import React, { Fragment } from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

import { FlyoutTarget } from '@jetshop/ui/Modal/Flyout';
import SearchProduct from './SearchProduct';
import SearchFlyoutCategories from './SearchFlyoutCategories';
import { theme } from '../../../Theming/Theming';
import Button from '../../../ui/Button';

export const SuggestedFlyout = styled('div')`
  position: absolute;
  right: 0;
  top: ${theme.mobileHeader.height.sm};
  background: white;
  padding: 1rem;
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  z-index: 1;
  max-width: 700px;
  max-height: 400px;
  overflow: auto;
  ${theme.above.lg} {
    top: 44px;
    width: 500px;
  }
  ${theme.below.lg} {
    left: 0;
  }
`;

const SearchFlyoutProducts = styled('div')``;

export const FlyoutTitle = styled('h4')`
  border-bottom: 1px solid ${theme.colors.borderLight};
  margin-bottom: 0.5rem !important;
  padding-bottom: 0.3rem;
  color: ${theme.colors.greys[4]};
`;

const ProductList = styled('ul')``;

const SearchAll = styled(Button)``;

const SearchFlyout = searchProps => {
  const products =
    (searchProps.result &&
      searchProps.result.products &&
      searchProps.result.products.result) ||
    [];
  return (
    <FlyoutTarget id="searchFlyout">
      {flyoutProps => {
        return (
          searchProps.isOpen &&
          flyoutProps.isOpen &&
          searchProps.isDirty && (
            <div {...searchProps.getFlyoutProps()}>
              <SuggestedFlyout>
                <SearchFlyoutCategories
                  term={searchProps.term}
                  closeSearch={searchProps.closeSearch}
                  hideTarget={flyoutProps.hideTarget}
                />
                <SearchFlyoutProducts>
                  {searchProps.loading
                    ? t('Loading…')
                    : products.length > 0 && (
                        <Fragment>
                          <FlyoutTitle>{t('Products')}</FlyoutTitle>
                          <ProductList>
                            {products.map(product => (
                              <SearchProduct
                                key={product.id}
                                product={product}
                                onClose={() => {
                                  searchProps.closeSearch();
                                  flyoutProps.hideTarget();
                                }}
                                term={searchProps.term}
                              />
                            ))}
                          </ProductList>
                          {products.length === 50 && (
                            <SearchAll
                              onClick={() => searchProps.triggerSearch()}
                            >
                              {t('View all')}
                            </SearchAll>
                          )}
                        </Fragment>
                      )}
                </SearchFlyoutProducts>
              </SuggestedFlyout>
            </div>
          )
        );
      }}
    </FlyoutTarget>
  );
};

export default SearchFlyout;
