import t from '@jetshop/intl';
import React from 'react';
import { css } from 'linaria';
import { ProductCard } from '../CategoryPage/ProductCard';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import CloseButton from '../ui/CloseButton';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import CustomData from '../ProductPage/CustomData';
import CustomProductSpecifications from '../ProductPage/ProductTabs/CustomProductSpecifications';
import useBuyButtons from '../ui/hooks/useBuyButtons';

export function Product({ product, loading, children }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  if (product.isVariant && product.variant.images) {
    productForDisplay.images = product.variant.images;
  }

  const buyButtons = useBuyButtons();

  return (
    <>
      <ProductCard
        product={productForDisplay}
        showFavorite={false}
        showVariants={false}
        as="div"
      >
        {buyButtons && (
          <>
            {product.hasVariants && (
              <div className="select-variant">
                <SelectVariant product={product} />
              </div>
            )}
            {product.validation.status === 'valid' ? (
              <AddToCart product={product} />
            ) : (
              <InvalidProduct validation={product.validation} />
            )}
            <RemoveFromList
              articleNumber={product.articleNumber}
              variant={product.variant}
            />
            <CustomData product={product} allDataFields={true}>
              {customFields => {
                return <CustomProductSpecifications fields={customFields} />;
              }}
            </CustomData>
          </>
        )}
      </ProductCard>
    </>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button
      className={removeStyles}
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    >
      <CloseButton />
    </button>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add'),
    package: t('Visit product page to add')
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}

const removeStyles = css`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  padding: 0.5em;
  svg {
    height: 1.25em;
    width: 1.25em;
  }
`;

const invalidProductStyles = css`
  background: #e7e7e7;
  height: 36px;
  text-align: center;
  padding: 0 1em;
  color: #555555;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;
