import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import { useContext } from 'react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarTextWrapper = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: ${theme.topBar.height.lg + 'px'};
  ${theme.below.md} {
    height: ${theme.topBar.height.md + 'px'};
  }
`;

const TopBarText = () => {
  const { TopBarText: items } = useContext(SiteContentContext);
  return (
    <TopBarTextWrapper className="top-bar-text">
      {items && items.show && items.html}
    </TopBarTextWrapper>
  );
};

export default TopBarText;
