import React, { memo, useState } from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { StyledCarrot } from '@jetshop/ui/Select/Components';
import t from '@jetshop/intl';
import { theme } from '../../../Theming/Theming';
import { ExtraMenuLinks } from '../Util/ExtraMenuLinks';

const CategoryWrapper = styled('div')`
  position: relative;
  overflow: hidden;
`;

const CategoryButton = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 0 1rem;
  border-bottom: 1px solid ${theme.colors.borderLight};
  background: ${theme.colors.white};
  ${StyledCarrot} {
    margin-right: 0px;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  &[data-level='1'] {
    font-family: ${theme.mobileHeader.nav.lv1.fontFamily};
    font-weight: ${theme.mobileHeader.nav.lv1.fontWeight};
    font-size: ${theme.mobileHeader.nav.lv1.fontSize};
    line-height: ${theme.mobileHeader.nav.lv1.lineHeight};
    border-bottom: 2px solid ${theme.colors.white};
    background: ${theme.colors.greys[1]};
    text-transform: ${theme.mobileHeader.nav.lv1.textTransform};
  }
  &[data-level='2'] {
    font-size: ${theme.mobileHeader.nav.lv2.fontSize};
    line-height: ${theme.mobileHeader.nav.lv2.lineHeight};
  }
  &[data-level='3'] {
    font-size: ${theme.mobileHeader.nav.lv3.fontSize};
    line-height: ${theme.mobileHeader.nav.lv3.lineHeight};
    border-left: 20px solid #e0e0e0;
  }
  &.active,
  &.active > a {
    color: ${theme.mobileHeader.nav.activeColor};
  }
  &.selected,
  &.selected > a {
    color: ${theme.mobileHeader.nav.selectedColor};
  }
`;

const CategoryHasSubs = styled('span')`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

function inPath(category) {
  let currentPath = '/';
  let returnObject = {
    inPath: false,
    exactPath: false
  };
  if (typeof window !== 'undefined') currentPath = window.location.pathname;
  if (
    category.primaryRoute &&
    currentPath.search(category.primaryRoute.path) !== -1
  ) {
    returnObject.inPath = true;
    returnObject.exactPath = currentPath === category.primaryRoute.path;
  }
  return returnObject;
}

const Category = memo(
  ({
    children,
    category,
    defaultOpen = false,
    level,
    hideTarget,
    activeCategory,
    setActiveCategory
  }) => {
    const hasSubs = category.subcategories && category.subcategories.length > 0;

    let classNames = [];
    const po = inPath(category);

    if (po.inPath) {
      defaultOpen = true;
      classNames.push('active');
    }

    if (po.exactPath || activeCategory === category.id)
      classNames.push('selected');

    const [isOpen, setOpen] = useState(defaultOpen);
    return (
      <CategoryWrapper>
        <CategoryButton data-level={level} className={classNames.join(' ')}>
          {hasSubs ? (
            <CategoryHasSubs onClick={() => setOpen(!isOpen)}>
              {category.name}
              <StyledCarrot open={isOpen} />
            </CategoryHasSubs>
          ) : (
            <CategoryLink
              category={category}
              onClick={() => {
                hideTarget();
                setActiveCategory(category.id);
              }}
            >
              {category.name}
            </CategoryLink>
          )}
        </CategoryButton>
        {isOpen && (
          <div>
            {hasSubs && (
              <CategoryButton data-level={level + 1}>
                <CategoryLink
                  category={category}
                  onClick={() => {
                    hideTarget();
                    setActiveCategory(category.id);
                  }}
                >
                  {t('Show all')}
                </CategoryLink>
              </CategoryButton>
            )}
            {children}
          </div>
        )}
      </CategoryWrapper>
    );
  }
);

function renderTree(
  parent,
  categories,
  level,
  hideTarget,
  activeCategory,
  setActiveCategory
) {
  if (categories && categories.length > 0) {
    return categories.map(category => (
      <Category
        category={category}
        key={category.id}
        level={level}
        hideTarget={hideTarget}
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
      >
        {renderTree(
          category,
          category.subcategories,
          level + 1,
          hideTarget,
          activeCategory,
          setActiveCategory
        )}
      </Category>
    ));
  } else return null;
}

const ExtraLink = ({ children }) => {
  return (
    <CategoryWrapper>
      <CategoryButton data-level={1}>{children}</CategoryButton>
    </CategoryWrapper>
  );
};

const MobileCategories = ({ categories, hideTarget }) => {
  const [activeCategory, setActiveCategory] = useState('trail');
  if (categories && categories.length > 0) {
    return (
      <div>
        {renderTree(
          null,
          categories,
          1,
          hideTarget,
          activeCategory,
          setActiveCategory
        )}
        <ExtraMenuLinks tag={ExtraLink} />
      </div>
    );
  } else return null;
};

export default MobileCategories;
