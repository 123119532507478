import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import { useContext } from 'react';
import SiteContentContext from '../../SiteContent/SiteContentContext';

export const FooterContentWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  ${theme.below.md} {
    flex-direction: column;
  }
  div.footer-header{
    width: 100%;
    ${theme.above.lg} {
      text-align: center;
    }
  }
  > div {
    width: 100%;
    flex-grow: 1;
    padding: 2rem 2rem;
    ${theme.only.md} {
      width: 100%;
    }
    ${theme.above.lg} {
      width: 23.3333%;
      padding: 3rem 0rem;
    }
    > h3 {
      margin-bottom: 0.5rem;
      line-height: 1.5em;
      font-size: 1.2rem;
      text-align: left;
      text-transform: uppercase;
    }
    * {
    }
    li,
    p {
      padding: 0;
      line-height: 2.2em;
      font-size: 0.9rem;
    }
    &.logo-wrapper {
      ${theme.above.lg} {
        width: 30%;
        padding: 3rem 1rem;
      }
    }
    >ul{
      >li{
        >a{
          >img{
            width: 20px;
            margin-right: 5px;
          }
          >span{

          }
        }
      }
    }
  }

  .link-content {
    display: flex;
    .link-image {
      margin-right: 10px;
      margin-bottom: 15px;
      width: 20px;
  }
    }
  }

  .icon-title {
    margin-top: 2rem;
  }
  .icon-list {
    display: flex;
    justify-content: center;
    li {
      padding: 0 0.5rem;
    }
  }
  .social-list {
    display: flex;
    margin-top: 2rem;
    >a{
      &:not(:first-child){
        margin-left: 2rem;
      }
      >img{
        max-width: 20px;
        max-height: 20px;
      }
    }
  }
  .footer-logo{
    display: block;
    max-width: 500px;
  }
`;

const FooterContent = () => {
  const { Footer: item } = useContext(SiteContentContext);
  if (!item) return null;
  return (
    <FooterContentWrapper>
      {item && item.show && item.html}
    </FooterContentWrapper>
  );
};

export default FooterContent;
