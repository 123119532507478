import React from 'react';
import { styled } from 'linaria/react';

import { theme } from '../Theming/Theming';

const NumberBadgeWrapper = styled('span')`
  ${`
    display: block;
    position: absolute;
    right: -10px;
    top: 12px;
    width: 18px;
    height: 18px;
    font-size: 0.8rem;
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    ${theme.below.lg} {
      width: 15px;
      height: 15px;
      font-size: 10px;
      right: -8px;
      top: 0px;
    }
    > span {
      display: block;
      position: relative;
      top: 1px;
      left: 1px;
    }
  `}
`;

export const NumberBadge = ({ text, color, background }) => {
  return (
    <NumberBadgeWrapper
      className="number-badge"
      color={color}
      background={background}
    >
      <span>{text}</span>
    </NumberBadgeWrapper>
  );
};
