import bodyFontReg from './Univers-reg.woff';
import bodyFontBold from './Univers-bold.woff';
import bodyFontLight from './Univers-light.woff';

import bodyFontReg2 from './Univers-reg.woff2';
import bodyFontBold2 from './Univers-bold.woff2';
import bodyFontLight2 from './Univers-light.woff2';

import TradeGothic from './TradeGothic.woff';
import TradeGothic2 from './TradeGothic.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Univers';
        src: url(${bodyFontReg}) format('woff'),
          url(${bodyFontReg2}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Univers';
        src: url(${bodyFontBold}) format('woff'),
          url(${bodyFontBold2}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'Univers';
        src: url(${bodyFontLight}) format('woff'),
          url(${bodyFontLight2}) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'TradeGothic';
        src: url(${TradeGothic}) format('woff'),
          url(${TradeGothic2}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
