import bodyFontReg from './Helvetica Neue.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'Helvetica';
        src: url(${bodyFontReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
