import bodyFontReg from './news_gothic_bt.woff2';
import headerFontReg from './news_gothic_condensed_bt.woff2';
import headerFontBold from './news_gothic_condensed_bt-bold.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'NewsGothic';
        src: url(${bodyFontReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'NewsGothicCondensed';
        src: url(${headerFontReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'NewsGothicCondensed';
        src: url(${headerFontBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
