import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import searchFlyoutCategoriesQuery from './SearchFlyoutCategoriesQuery.gql';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from '../../../Theming/Theming';
import { FlyoutTitle } from './SearchFlyout';

const CatList = styled('ul')`
  margin-bottom: 1rem;
`;

const CatItem = styled('li')`
  padding: 0.3rem 0.2rem;
  &:hover {
    background: ${theme.colors.greys[0]};
  }
`;

const CatItemName = styled('div')`
  display: flex;
  align-items: center;
  h4 {
  }
  span {
    color: #ccc;
    margin-left: 10px;
    font-size: 0.9rem;
  }
`;

const SearchFlyoutCategories = ({ term, closeSearch, hideTarget }) => (
  <Query variables={{ levels: 3 }} query={searchFlyoutCategoriesQuery}>
    {result => {
      const { data } = result;
      const categories = data && data.categories;
      const filteredCategories =
        (categories &&
          categories.filter(category => {
            return (
              category.name.toLowerCase().search(term.toLowerCase()) !== -1
            );
          })) ||
        [];
      return (
        <Fragment>
          {filteredCategories.length ? (
            <>
              <FlyoutTitle>{t('Categories')}</FlyoutTitle>
              <CatList>
                {filteredCategories.map(category => (
                  <CatItem key={category.id}>
                    <CategoryLink
                      category={category}
                      onClose={() => {
                        closeSearch();
                        hideTarget();
                      }}
                    >
                      <CatItemName>
                        <h4>{category.name}</h4>
                        <span>{category.primaryRoute.path}</span>
                      </CatItemName>
                    </CategoryLink>
                  </CatItem>
                ))}
              </CatList>
            </>
          ) : null}
        </Fragment>
      );
    }}
  </Query>
);

export default SearchFlyoutCategories;
