import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Price } from '@jetshop/ui/Price';
import { ProductCard } from './ProductCard';
import { theme } from '../Theming/Theming';

export const priceStyle = css`
  ${Price.Wrapper} {
    display: flex;
  }
`;

export const GridWrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -5px;
  margin-left: -5px;
  ${theme.above.md} {
    margin-right: -8px;
    margin-left: -8px;
  }
  ${theme.above.xl} {
    margin-right: -12px;
    margin-left: -12px;
  }
  .product-card {
    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
    .product-card-inner {
      margin: 0 5px 10px;
      ${theme.above.md} {
        margin: 0 8px 16px;
      }
      ${theme.above.xl} {
        margin: 0 12px 24px;
      }
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <GridWrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            list={listName}
            {...rest}
          ></ProductCard>
        );
      })}
    </GridWrapper>
  );
}

export default ProductGrid;
