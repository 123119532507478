import React from 'react';
import { config } from '../shop.spec.config.js';
import CustomFont from '../fonts/Components/CustomFont';
import TypekitFont from './Components/TypekitFont';
import { theme } from '../components/Theming/Theming';
import { shopFont } from '../components/Theming/switch';

export const FontHandler = () => {
  const typeKitStores = ['skiforeningen', 'nordicgrip', 'camelbak', 'hallmark'];
  if (typeKitStores.includes(config.storeId))
    return (
      <TypekitFont
        primaryFont={theme.font.primary}
        secondaryFonts={[theme.font.secondary]}
        id={theme.font.id}
      />
    );
  else
    return (
      <CustomFont
        primaryFont={theme.font.primary}
        secondaryFonts={[theme.font.secondary]}
        injectCss={shopFont()}
      />
    );
};
