import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import ProductLink from '@jetshop/ui/ProductLink';
import { ListPrice, Name } from '../../../CategoryPage/ProductCard';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';

const SearchProductWrapper = styled('li')`
  padding: 0.5rem 0.3rem;
  border-bottom: 1px solid ${theme.colors.borderLight};
  &:hover {
    background: ${theme.colors.greys[0]};
  }
`;

const ProductImageWrapper = styled('div')`
  width: 15%;
  .image-holder {
  }
`;

const StyledProductLink = styled(ProductLink)`
  display: flex;
`;

const ProductInfo = styled('div')`
  width: 80%;
  padding-left: 1rem;
  line-height: 1;
  > div {
  }
  .product-name {
    height: auto;
  }
`;

const StyledListPrice = styled(ListPrice)`
  padding: 0;
  > div {
    font-size: 0.9rem !important;
  }
  [data-flight-price] {
    justify-content: flex-start;
  }
`;

const SearchProduct = ({ product, onClose, term }) => {
  const hasImages = product.images && product.images.length > 0;
  return (
    <SearchProductWrapper>
      <StyledProductLink product={product} onClick={onClose}>
        <ProductImageWrapper>
          {hasImages ? (
            <Image
              sizes={100}
              aspect={'1:1'}
              alt={product.images[0].alt}
              src={product.images[0].url}
            ></Image>
          ) : (
            <Image src={transparentDataImg} />
          )}
        </ProductImageWrapper>
        <ProductInfo>
          <Name className="product-name">{product.name}</Name>
          <StyledListPrice
            price={product.price}
            recommendedPrice={product.recommendedPrice}
            previousPrice={product.previousPrice}
          />
        </ProductInfo>
      </StyledProductLink>
    </SearchProductWrapper>
  );
};

export default SearchProduct;
