import React from 'react';
import { styled } from 'linaria/react';
import { LoginWidget } from '../Util/LoginWidget';
import { useContext } from 'react';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarLinksWrapper = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  > ul {
    > li {
    }
  }
`;

const TopBarLinks = () => {
  const { TopBarLinks: items } = useContext(SiteContentContext);

  return (
    <TopBarLinksWrapper className="top-bar-links-list">
      {items && items.show && items.html}
      <LoginWidget />
    </TopBarLinksWrapper>
  );
};

export default TopBarLinks;
