import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theming/Theming';

const ColorWrapper = styled('div')`
  &.selected > div {
    border-color: ${theme.colors.black};
  }
  > div {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid ${theme.colors.white};
  }
`;

export const Color = ({ className, color }) => {
  return (
    <ColorWrapper className={cx('color-circle', className)}>
      <div style={{ backgroundColor: '#' + color }} />
    </ColorWrapper>
  );
};
