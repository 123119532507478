import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theming/Theming';
import SiteContentContext from '../../../SiteContent/SiteContentContext';

const TopBarUspWrapper = styled('div')`
  > ul {
    > li {
      display: flex;
      align-items: center;
      font-size: ${theme.topBar.usp.fontSize};
      font-weight: ${theme.topBar.usp.fontWeight};
      ${theme.only.sm} {
        font-size: 0.8rem !important;
      }
      ${theme.only.xs} {
        font-size: 0.75rem !important;
      }
      svg {
        margin-right: 5px;
      }
      ${theme.only.xs} {
        &:first-child {
          padding-left: 0 !important;
        }
        &:last-child {
          padding-right: 0 !important;
        }
      }
      ${theme.below.md} {
        justify-content: center;
        &:nth-of-type(3),
        &:nth-of-type(4) {
          display: none;
        }
      }
    }
  }
`;

const TopBarUsp = ({ name }) => {
  const content = useContext(SiteContentContext);
  const TopBarUsp = content[name];
  if (TopBarUsp && TopBarUsp.show) {
    return (
      <TopBarUspWrapper className="top-bar-usp-list secondary-font">
        {TopBarUsp.html}
      </TopBarUspWrapper>
    );
  }
  return null;
};

export default TopBarUsp;
