import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import React from 'react';
import { css, cx } from 'linaria';
import { useNotification } from '@jetshop/core/components/Notifications';
import { ReactComponent as Cart } from '../../svg/Cart.svg';
import addMultipleToCartMutation from '../Cart/queries/addMultipleToCart.gql';
import cartQuery from '../Cart/queries/cartQuery.gql';
import MaxWidth from '../Layout/MaxWidth';
import { theme } from '../Theming/Theming';
import Button from '../ui/Button';
import { Product } from './Product';
import { ScrollSlider } from '../ui/ScrollSlider';

export function Favourites() {
  const listId = null;
  const { products, loading } = useProductListItems(listId);
  const [trigger] = useNotification();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        trigger(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <MaxWidth className={cx(container, loading && 'loading')}>
        <h1>{t('Saved items')}</h1>
        <p>{t('There are no products in your saved items list.')}</p>
        {validItemCount > 0 && (
          <div className="add-clear">
            <ClearList>{t('Clear old products')}</ClearList>
          </div>
        )}
      </MaxWidth>
    );
  }

  if (loadingInitialRender) {
    return (
      <MaxWidth className={cx(container, loading && 'loading')}>
        <h1>{t('Saved items')}</h1>
        <p>{t('Loading your saved items…')}</p>
      </MaxWidth>
    );
  }

  return (
    <MaxWidth className={cx(container, loading && 'loading')}>
      <h1>{t('Saved items')}</h1>
      <ScrollSlider>
        {products.map((product, index) => {
          return (
            <div
              key={product.variant?.articleNumber || product.articleNumber}
              data-valid={product.validation.status}
              className="product-card product-fav-wrapper"
            >
              <Product product={product} />
            </div>
          );
        })}
      </ScrollSlider>
      <div className="add-clear">
        <Button
          style={{ marginTop: '2em' }}
          onClick={addToCart}
          loading={mutationLoading}
          className="add-all"
          disabled={validItemCount === 0}
        >
          {validItemCount > 0
            ? mutationLoading
              ? t('One moment…')
              : t(
                  '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                  { productCount: validItemCount }
                )
            : t('No valid items to add to cart')}
        </Button>
        <ClearList>{t('Clear list')}</ClearList>
      </div>
    </MaxWidth>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <Button secondary onClick={clear}>
      {children}
    </Button>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const container = css`
  margin-bottom: 5rem;
  margin-top: 2rem;
  &.loading {
    opacity: 0.6;
  }

  h1 {
    margin: 0 0 1rem 0;
  }

  //GRID
  .product-grid {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    width: 100%;
    > * {
      line-height: 19px;
      min-width: 0;
      margin: 0.5rem;
      min-width: 60%;
      ${theme.above.md} {
        min-width: 38%;
      }
      ${theme.above.lg} {
        min-width: 28%;
      }
      ${theme.above.xl} {
        min-width: 22%;
      }
    }
  }

  [data-valid] .product-card {
  }
  [data-valid='valid'] .product-card {
  }

  //PRODUCT CARD
  .product-fav-wrapper {
    width: 60%;
    ${theme.above.md} {
      width: 38%;
    }
    ${theme.above.lg} {
      width: 28%;
    }
    ${theme.above.xl} {
      width: 22%;
    }
  }
  .product-card-detail,
  .product-card,
  .product-card a {
  }
  li:not([data-valid='valid']) .product-card {
    background: rgba(255, 255, 255, 0.2);
    a {
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .product-card-inner {
    padding-bottom: 36px;
  }

  //VARIANTS
  .select-variant {
    margin: 1rem 0 0 0;
    position: relative;
    [data-flight-dropdown-button],
    [data-flight-dropdown-item] {
      height: 46px !important;
      font-size: 0.9rem;
    }
  }

  .add-clear button {
    max-width: 40ch;
    display: block;
    margin: 0.5em auto;
  }

  .specifications-wrapper {
    text-align: center;
    .field-wrapper {
      margin: 1rem 0;
      padding-top: 1rem;
      border-top: 1px solid #eee;
      .field-title {
        margin-bottom: 0.3rem;
      }
      &[data-type='LIST'] {
        ul {
          li {
            margin: 5px 0;
          }
        }
      }
    }
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;
