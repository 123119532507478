import bodyFontReg from './TitilliumWeb-Regular.woff2';
import bodyFontBold from './TitilliumWeb-Bold.woff2';
import bodyFontSemiBold from './TitilliumWeb-SemiBold.woff2';
import body2Font from './Knockout.woff2';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'titillium';
        src: url(${bodyFontReg}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'titillium';
        src: url(${bodyFontBold}) format('woff2');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'titillium';
        src: url(${bodyFontSemiBold}) format('woff2');
        font-weight: 600;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'knockout';
        src: url(${body2Font}) format('woff2');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
