import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theming/Theming';
import { getFontSize } from '../Theming/helpers';

const buttonStyle = `
  height: 54px;
  font-family: ${theme.button.fontFamily};
  font-weight: ${theme.button.fontWeight};
  text-transform: ${theme.button.textTransform};
  ${getFontSize(theme.button.fontSize)};
  background-color: ${theme.button.background};
  color: ${theme.button.color};
  padding: 0 0.5rem;
  letter-spacing: ${theme.button.letterSpacing};
  border-radius: ${theme.button.radius};
  ${theme.above.md} {
    max-width: 100%;
  }
  >a{
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  > a,
  > a:focus {
    color: ${theme.button.color};
  }
  svg {
    fill: white;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  :active {
    color: ${theme.button.color};
  }
  :disabled:not(.same) {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }

  &.secondary{
    background-color: ${theme.button.backgroundSecondary};
    color: ${theme.button.colorSecondary};
    :active {
      color: ${theme.button.colorSecondary};
    }
  }
  &.buy, &.cta{
    background-color: ${theme.productPage.buyButton.background};
    color: ${theme.productPage.buyButton.color};
    &:hover {
      background-color: ${theme.productPage.buyButton.background};
      color: ${theme.productPage.buyButton.color};
    }
  }
  &.hollow{
    background: none;
    border: 1px solid ${theme.colors.font};
    color: ${theme.colors.font};
    :active {
      color: ${theme.colors.font};
    }
  }
  &.list{
    height: 36px;
    ${getFontSize(theme.button.ListFontSize)};
  }
`;

const Button = styled(UIButton)`
  ${buttonStyle};
`;

export const TrendLink = styled(Link)`
  ${buttonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export const ButtonLink = styled(Link)`
  ${buttonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(
        props.className,
        props.secondary ? 'secondary' : null,
        props.buy && 'buy',
        props.cta && 'cta',
        props.hollow && 'hollow',
        props.list && 'list'
      )}
    >
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(
        props.className,
        props.secondary ? 'secondary' : null,
        props.buy && 'buy',
        props.cta && 'cta',
        props.hollow && 'hollow',
        props.list && 'list'
      )}
    />
  );

export default ButtonWithLoading;
