import bodyFontReg from './DINNextLTProMedium.woff';
import bodyFontUltraLight from './DINNextLTProUltraLight.woff';
import bodyFontLight from './DINNextLTProLight.woff';

import body2FontUltraLight from './DINNextLTProUltraLightCond.woff';
import body2FontLight from './DINNextLTProLightCond.woff';

import { css } from 'linaria';

export default function loadCss() {
  return css`
    :global(html) {
      /* Regular font */
      @font-face {
        font-family: 'DinNext';
        src: url(${bodyFontReg}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'DinNext';
        src: url(${bodyFontLight}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'DinNext';
        src: url(${bodyFontUltraLight}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'DinNextCond';
        src: url(${body2FontLight}) format('woff');
        font-weight: 300;
        font-style: normal;
        font-display: swap;
      }
      @font-face {
        font-family: 'DinNextCond';
        src: url(${body2FontUltraLight}) format('woff');
        font-weight: 100;
        font-style: normal;
        font-display: swap;
      }
    }
  `;
}
