import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';

const PercentWrapper = styled('div')`
  display: inline-block;
  font-size: 0.9rem;
  background: ${theme.colors.red};
  color: white;
  padding: 1px 6px;
  border-radius: 2px;
  &.badge {
    padding: 1px 6px;
    border-radius: 50%;
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 42px;
    width: 42px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: ${theme.font.secondary};
    font-weight: ${theme.weights.bold};
  }
`;

export const PercentPrice = props => {
  if (props.previousPrice && props.previousPrice.incVat > props.price.incVat) {
    const percent = Math.round(
      ((props.previousPrice.incVat - props.price.incVat) /
        props.previousPrice.incVat) *
        100
    );
    const percentString = `-${percent}%`;
    return (
      <PercentWrapper className={`percent-price ${props.className}`}>
        {percentString}
      </PercentWrapper>
    );
  }
  return null;
};
