import PropTypes from 'prop-types';
import React from 'react';
import StockOrb from './StockOrb';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';

const StockStatusWrapper = styled('p')`
  margin: 0 0 0 0;
  display: flex;
  align-items: center;
`;

const StockStatusIndicator = ({ status, text }) => {
  if (text?.search('Bestillingsvare') !== -1 && status === 'inStock')
    status = 'notifyWhenBack';

  return (
    <StockStatusWrapper css={{}}>
      <StockOrb status={status} />
      <span>{stockStatusMask(text)}</span>
    </StockStatusWrapper>
  );
};

export const stockStatusMask = status => {
  const value = parseInt(status);
  if (value < 1) return t('Out of stock');
  if (value > 5) return t('In stock');
  return status;
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;
