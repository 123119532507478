import React from 'react';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';

const Logo = ({ mobile = false, size = [theme.logo.size] }) => {
  let src = '/pub_images/original/logo.png?v=2';
  if (mobile) src = '/pub_images/original/logo.png?v=2';
  return (
    <Image
      src={src}
      sizes={size}
      aspect={theme.logo.aspect}
      crop={false}
      quality={100}
    />
  );
};

export default Logo;
