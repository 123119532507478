import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image';
import { theme } from '../../Theming/Theming';

export const SpecificationsWrapper = styled('div')`
  width: 100%;
  overflow: hidden;
  ul.feature-list {
    margin-top: 1rem;
    li {
      margin: 15px 0;
      display: flex;
      align-items: center;
      .feature-icon {
        width: 25px;
        position: relative;
        top: -3px;
      }
      .feature-text {
        margin-left: 0.7rem;
      }
    }
  }
  ul.points-list {
    margin-top: 1rem;
    li {
      margin: 10px 0;
      display: flex;
      align-items: center;
      text-align: left;
      .points-bar {
        display: flex;
        > span {
          background: #eee;
          margin: 0 3px;
          display: block;
          width: 15px;
          height: 15px;
          &.filled {
            background: #444;
          }
        }
      }
      .points-text {
        width: 160px;
      }
    }
  }
  &.product-page {
    max-width: 700px;
    margin: 2rem auto;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #111;
    border-left: 1px solid #111;
    .field-wrapper {
      width: 100%;
      ${theme.above.md} {
        width: 50%;
      }
      padding: 1rem;
      border-bottom: 1px solid #111;
      border-right: 1px solid #111;
    }
  }
`;

export const FieldWrapper = styled('div')``;

export const FieldTitle = styled('h5')``;

export const FieldData = styled('div')``;

const StringValues = ({ stringValue }) => <div>{stringValue}</div>;
const HtmlValues = ({ htmlValue }) => (
  <div
    dangerouslySetInnerHTML={{
      __html: htmlValue
    }}
  />
);
const BoolValues = ({ boolValue }) => (
  <div>{boolValue ? t('Yes') : t('No')}</div>
);
const ListValues = ({ listValues }) => (
  <ul>
    {listValues.map((listItem, index) => (
      <li key={index}>{listItem}</li>
    ))}
  </ul>
);

const FeatureData = ({ fields }) => (
  <ul className="feature-list">
    {fields.map((field, index) => {
      const iconName = field.replace(/[^a-z0-9]/gi, '').toLowerCase();
      const text = field;
      return (
        <li key={index} className="feature-item">
          <span className="feature-icon">
            <Image
              src={'/pub_images/original/' + iconName + '.png'}
              alt={iconName}
              size={[40]}
              aspect={'1:1'}
            />
          </span>
          <span className="feature-text">{text}</span>
        </li>
      );
    })}
  </ul>
);

const PointData = ({ fields }) => (
  <ul className="points-list">
    {fields.map((field, index) => {
      const number = field.stringValue;
      const text = field.title;
      return (
        <li key={index} className="points-item">
          <span className="points-text">{text}</span>
          <span className="points-bar">
            {[...Array(5).keys()].map((item, index) => (
              <span
                key={index}
                className={index < number ? 'filled' : 'empty'}
              />
            ))}
          </span>
        </li>
      );
    })}
  </ul>
);

const FieldDataRender = ({ field }) => {
  const type = field.type;
  switch (type) {
    case 'STRING':
      return <StringValues stringValue={field.stringValue} />;
    case 'HTML':
      return <HtmlValues htmlValue={field.htmlValue} />;
    case 'BOOL':
      return <BoolValues boolValue={field.boolValue} />;
    case 'LIST':
      return <ListValues listValues={field.listValues} />;
    default:
      break;
  }
};

const FieldRender = ({ title, type, index, children }) => (
  <FieldWrapper className="field-wrapper" key={index} data-type={type}>
    <FieldTitle className="field-title">{title}</FieldTitle>
    <FieldData className="field-data">{children}</FieldData>
  </FieldWrapper>
);

const CustomProductSpecifications = ({
  fields,
  className = '',
  productPage = false
}) => {
  let standardFields = [];
  let pointFields = [];
  let featureFields = [];

  fields?.length > 0 &&
    fields.forEach(field => {
      if (field.key.toLowerCase().search('features-') !== -1)
        featureFields.push(field);
      else if (field.key.toLowerCase().search('points-') !== -1)
        pointFields.push(field);
      else standardFields.push(field);
    });

  return fields ? (
    <SpecificationsWrapper
      className={`specifications-wrapper ${className} ${
        productPage ? 'product-page' : ''
      }`}
    >
      {featureFields.length > 0 &&
        featureFields.map(field => {
          return (
            <FieldRender type={'custom'} title={field.title} index={100}>
              <FeatureData fields={field.listValues} />
            </FieldRender>
          );
        })}
      {pointFields.length > 0 && (
        <FieldRender type={'custom'} title={t('Characteristics')} index={200}>
          <PointData fields={pointFields} />
        </FieldRender>
      )}
      {standardFields.map((field, index) => (
        <FieldRender
          key={index}
          type={field.type}
          title={field.title}
          index={index}
        >
          <FieldDataRender field={field} />
        </FieldRender>
      ))}
    </SpecificationsWrapper>
  ) : null;
};
export default CustomProductSpecifications;
