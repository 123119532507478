import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { theme } from '../Theming/Theming';
import { Price } from '../Price';
import RemoveFromCart from '@jetshop/core/components/Mutation/RemoveFromCart';
import DecrementQuantity from '@jetshop/core/components/Mutation/DecrementQuantity';
import IncrementQuantity from '@jetshop/core/components/Mutation/IncrementQuantity';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import CloseButton from '../ui/CloseButton';
import ProductLink from '@jetshop/ui/ProductLink';
import cartQuery from './queries/cartQuery.gql';
import MinusIcon from '../ui/icons/MinusIcon';
import PlusIcon from '../ui/icons/PlusIcon';
import { Query } from 'react-apollo';
import PrintQuery from '../ProductPage/PrintProducts/PrintQuery.gql';

const Wrapper = styled('div')`
  display: flex;
  background: white;
  padding: 0 0 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${theme.colors.border};
  position: relative;
`;

const ProductName = styled('h2')`
  ${`
    color: #555;
    font-size: 1.2rem;
    font-family: ${theme.productGrid.product.name.fontFamily} !important;
    font-weight: ${theme.productGrid.product.name.fontWeight} !important;
    padding-right: 2rem;
  `}
`;

const ProductVariant = styled('p')`
  color: #828282;
  font-size: 0.85rem;
  margin-top: 5px;
`;

const ProductComments = styled('div')`
  color: #828282;
  font-size: 0.85rem;
  margin-top: 5px;
`;

const Comment = styled('div')`
  > span {
    > span {
      margin-right: 5px;
    }
  }
`;

const ProductImage = styled('div')`
  width: 25%;
`;

const ProductDetail = styled('section')`
  width: 75%;
  padding-left: 1rem;
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'top top' 'bottomleft bottomright' 'bottom bottom';
`;

const ItemDetails = styled('div')`
  grid-area: top;
`;

const PriceWrapper = styled('div')`
  grid-area: bottomright;
`;

const PrintPriceWrapper = styled('div')`
  grid-area: bottom;
  text-align: right;
  > span {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`;

const PrintPrice = styled(Price)`
  display: inline-block;
`;

const LinePrice = styled(Price)`
  margin-top: 1rem;
  text-align: right;
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: #f2f2f2;
    color: black;
    height: 20px;
    width: 20px;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    :focus {
      outline: none;
    }
    svg {
      color: #333;
      font-size: 0.8rem;
    }
    i {
      display: flex !important;
    }
  }
  /* Count */
  span {
    margin: 0 0.5rem;
    display: inline-block;
    line-height: 20px;
  }
`;

const RemoveItem = styled('div')`
  position: absolute;
  top: 2px;
  right: 0;
  button {
    display: inline-block;
    position: relative;
    top: 0;
    padding: 3px;
    margin: -3px;
  }
  svg {
    color: #999;
  }
`;

const adjustItem = (item, adjustFunction) => {
  adjustFunction(item.id, item.product).then(() => {
    if (item.relatedPrint)
      adjustFunction(item.relatedPrint.id, item.relatedPrint.product);
  });
};

const CartItem = ({ item, className = '', close }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);
  return (
    <Wrapper className={className}>
      <ProductImage>
        {item.product.images.length > 0 && (
          <ProductLink product={item.product} onClick={close}>
            <Image
              aspect="3:3"
              sizes={[200]}
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
            />
          </ProductLink>
        )}
      </ProductImage>
      <ProductDetail>
        <ItemDetails>
          <ProductLink product={item.product} onClick={close}>
            <ProductName>{item.product.name}</ProductName>
          </ProductLink>
          {isVariant && (
            <ProductVariant>{variantValues.join(', ')}</ProductVariant>
          )}
          {item.customerComments && (
            <ProductComments>
              {item.customerComments.map((comment, index) => {
                return (
                  <Comment key={index}>
                    <span>
                      <Query
                        query={PrintQuery}
                        variables={{
                          articleNumber: comment.name
                        }}
                      >
                        {({ data }) => {
                          const product = data && data.product;
                          if (product) return <span>{product.name}:</span>;
                          else return <span>{comment.name}</span>;
                        }}
                      </Query>
                      {comment.value ? (
                        <span
                          dangerouslySetInnerHTML={{ __html: comment.value }}
                        ></span>
                      ) : (
                        '-'
                      )}
                    </span>
                  </Comment>
                );
              })}
            </ProductComments>
          )}
        </ItemDetails>

        <RemoveItem>
          <RemoveFromCart cartQuery={cartQuery}>
            {removeFromCart => (
              <CloseButton
                onClick={() => {
                  adjustItem(item, removeFromCart);
                }}
                size={24}
              />
            )}
          </RemoveFromCart>
        </RemoveItem>
        <AdjustQty>
          <DecrementQuantity cartQuery={cartQuery}>
            {decrementQuantity => (
              <button
                disabled={item.quantity === 1}
                onClick={() => {
                  item.quantity !== 1 && adjustItem(item, decrementQuantity);
                }}
              >
                <MinusIcon />
              </button>
            )}
          </DecrementQuantity>
          <span>{item.quantity}</span>
          <IncrementQuantity cartQuery={cartQuery}>
            {incrementQuantity => (
              <button
                onClick={() => {
                  adjustItem(item, incrementQuantity);
                }}
              >
                <PlusIcon />
              </button>
            )}
          </IncrementQuantity>
        </AdjustQty>
        <PriceWrapper>
          <LinePrice price={item.total} />
        </PriceWrapper>
        {item.relatedPrint && (
          <PrintPriceWrapper>
            <span>{item.relatedPrint.product.name}</span>
            <span>{item.quantity} x</span>
            <span>
              <PrintPrice price={item.relatedPrint.product.price} />
            </span>
          </PrintPriceWrapper>
        )}
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
