//THEME
import { leathermanTheme } from './stores/leatherman';
import { ledlenserTheme } from './stores/ledlenser';
import { bruslettoTheme } from './stores/brusletto';
import { skiforeningenTheme } from './stores/skiforeningen';
import { nordicgripTheme } from './stores/nordicgrip';
import { crispiTheme } from './stores/crispi';
import { camelbakTheme } from './stores/camelbak';
import { alpinaTheme } from './stores/alpina';
import { orackTheme } from './stores/orack';
import { hallmarkTheme } from './stores/hallmark';
import { stanleyTheme } from './stores/stanley';
import { giroTheme } from './stores/giro';

//FONTS
import leathermanFonts from '../../fonts/leatherman/loadFontCss';
import ledlenserFonts from '../../fonts/ledlenser/loadFontCss';
import bruslettoFonts from '../../fonts/brusletto/loadFontCss';
import crispiFonts from '../../fonts/crispi/loadFontCss';
import alpinaFonts from '../../fonts/alpina/loadFontCss';
import stanleyFonts from '../../fonts/stanley/loadFontCss';
import orackFonts from '../../fonts/orack/loadFontCss';
import giroFonts from '../../fonts/giro/loadFontCss';
import { config } from '../../shop.spec.config.js';

const shopId = config.storeId;

export const shopTheme = () => {
  switch (shopId) {
    case 'ledlenser':
      return ledlenserTheme;
    case 'ledlenser-test':
      return ledlenserTheme;
    case 'brusletto':
      return bruslettoTheme;
    case 'skiforeningen':
      return skiforeningenTheme;
    case 'nordicgrip':
      return nordicgripTheme;
    case 'crispi':
      return crispiTheme;
    case 'camelbak':
      return camelbakTheme;
    case 'alpina':
      return alpinaTheme;
    case 'orack':
      return orackTheme;
    case 'hallmark':
      return hallmarkTheme;
    case 'stanley':
      return stanleyTheme;
    case 'giro':
      return giroTheme;
    default:
      return leathermanTheme;
  }
};

export const shopFont = () => {
  switch (shopId) {
    case 'ledlenser':
      return ledlenserFonts;
    case 'ledlenser-test':
      return ledlenserFonts;
    case 'brusletto':
      return bruslettoFonts;
    case 'crispi':
      return crispiFonts;
    case 'alpina':
      return alpinaFonts;
    case 'stanley':
      return stanleyFonts;
    case 'orack':
      return orackFonts;
    case 'giro':
      return giroFonts;
    default:
      return leathermanFonts;
  }
};
